<template>
  <div @click="toPay">支付</div>
</template>

<script>
export default {
  data() {
    return {
      msg: ""
    };
  },
  created() {
    this.msg = this.$route.query;
    console.log(this.msg, 3213);
  },
  methods: {
    toPay() {
      this.$http.getPayCode(this.msg.id, this.msg.code).then(res => {
        console.log(res, 312);
      });
    }
  }
};
</script>

<style>
</style>